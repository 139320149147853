import React from "react";

import Footer from "../layout/footer/Footer";
import Navbar from "../layout/header/Navbar";
import Layout from "../layout/Layout";
import PageHeader from "../team/PageHeader";
import HeroSix2 from "../../themes/index-6/HeroSix2";
import image5 from "../../img/SWAG group SSSA 24.png"




export default function Article7() {

  
  return (
    <Layout>
            <Navbar darkBg/>
      <HeroSix2 />
    
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
              <h3 style={{color:'#e95d04'}}>
              SWAG partners gather in Italy to progress key technology
                          </h3>
                          <br/>
                          <p>On 28th and 29th November, 2024 the SWAG project travelled to Italy for the M13 Plenary meeting hosted by SSSA and IUVO.  
                            The meeting served as an opportunity for the consortium consisting of <a style={{color:'#f45652'}}href='https://www.twi-hellas.com/'>TWI Hellas</a>, <a style={{color:'#f45652'}}href='https://csrl.hmu.gr/'>Hellenic Mediterranean University (HMU)</a>, <a style={{color:'#f45652'}}href='https://bendabl.com/'>Bendabl</a>, 
                <a style={{color:'#f45652'}}href='https://www.uni-heidelberg.de/'> Heidelberg University</a>, <a style={{color:'#f45652'}}href='https://www.santannapisa.it/en/institute/biorobotics'>Scuola Superiore Sant'Anna</a>, 
                <a style={{color:'#f45652'}}href='https://www.iuvo.company/'> IUVO</a>, <a style={{color:'#f45652'}}href='https://swag-project.eu/www.eurecat.org'>Eurecat</a>, 
                <a style={{color:'#f45652'}}href='https://nbr.ijs.si/'> Jožef Stefan Institute</a>, <a style={{color:'#f45652'}}href='https://www.utwente.nl/en/et/be/research/neuro-mech-eng-group/'>University of Twente (UT)</a>, 
                <a style={{color:'#f45652'}}href='https://www.rrd.nl/en/'> Roessingh Research and Development (RRD)</a>, 
               <a style={{color:'#f45652'}}href='https://www.herts.ac.uk/kaspar/research-and-development'>University of Hertfordshire</a>, <a style={{color:'#f45652'}}href='https://www.brunel.ac.uk/research/Centres/Brunel-Innovation-Centre/About-us'>Brunel Innovation Centre (BIC)</a>,
                 <a style={{color:'#f45652'}}href='https://www.imperial.ac.uk/bioengineering/research/'> Imperial College London</a> to convene, review the progress of the project and discuss key topics. 
                            Milestones achieved thus far were presented, and discussions were held to outline the next steps for the future development of the project.</p>
                            <img
                  src={image5}
                  style={{borderRadius:'10px',width:'450px',height:'auto',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <p>Technology reviewed included the user-centric adaptive smart actuator design and textile garment  development with exploration of different integration techniques, as well work on ensuring the best achievable neural and human-machine interfacing.
                </p>
                <p>The meeting concluded with a strengthened sense of collaboration and a shared commitment to advancing the SWAG project toward its future goals. 
                  The productive discussions and valuable insights gained from this meeting laid a solid foundation for the next phases of the project. We look forward to further successful collaboration and progress as we move ahead in 2025.</p>
 
 </div>      </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
