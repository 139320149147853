import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../components/others/ChangePassword";
import ComingSoon from "../components/others/ComingSoon";
import LoginTwo from "../components/others/LoginTwo";
import NotFound from "../components/others/NotFound";
import PasswordReset from "../components/others/PasswordReset";
import ThankYou from "../components/others/ThankYou";
import Login from "../pages/Login";
import HomeOne from "../themes/index-1/HomeOne";
import HomeTwo from "../themes/index-2/HomeTwo";
import HomeThree from "../themes/index-3/HomeThree";
import HomeFour from "../themes/index-4/HomeFour";
import HomeFive from "../themes/index-5/HomeFive";
import HomeSix from "../themes/index-6/HomeSix";
import HomeSeven from "../themes/index-7/HomeSeven";
import HomeEight from "../themes/index-8/HomeEight";
import OurTeam from "../pages/OurTeam";
import TeamDetails from "../pages/TeamDetails";
import Download from "../pages/Download";
import Review from "../pages/Review";
import Faq from "../pages/Faq";
import SignupTwo from "../components/others/SignupTwo";
import SignupPage from "../pages/SignupPage";
import BlogGrid from "../pages/BlogGrid";
import BlogWithSidebar from "../pages/BlogWithSidebar";
import BlogDetails from "../pages/BlogDetails";
import ScrollToTop from "./ScrollToTop";
import Outcomes from "../pages/Outcomes"
import News from "../pages/News"
import Partners from "../pages/Partners"
import Article1 from "../components/blogs/Article1"
import Article2 from "../components/blogs/Article2"
import Article3 from "../components/blogs/Article3"
import Contact from "../components/contact/Contact";
import PiwikPro from '@piwikpro/react-piwik-pro';
import Article4 from "../components/blogs/Article4";
import Article5 from "../components/blogs/Article5";
import Article6 from "../components/blogs/Article6";
import Article7 from "../components/blogs/Article7";


PiwikPro.initialize('d93fb129-26f5-4f34-9a84-ef5f495224d5', 'https://pantheon-project.piwik.pro');

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeSix/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/outcomes" element={<Outcomes/>} />
        <Route path="/partners" element={<Partners/>} />
        <Route path="/SWAG Project Launched to Revolutionise Soft Robotic Exosuits" element={<Article1/>} />
        <Route path="/SWAG debuts at online showcase event" element={<Article2/>} />
        <Route path="/SWAG Partners gather in The Netherlands to discuss latest developments" element={<Article3/>} />
        <Route path="/New research promises improved design and interfacing of exosuits" element={<Article4/>} />
        <Route path="/Read the First Project Newsletter" element={<Article5/>} />
        <Route path="/Towards Wearable Electromyography for Personalized Musculoskeletal Trunk Models using an Inverse Synergy-based Approach" element={<Article6/>} />
        <Route path="/SWAG partners gather in Italy to progress key technology" element={<Article7/>} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
